var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-documents"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),(_vm.isAction)?_c('div',{staticClass:"action"},[_c('q-btn',{staticClass:"btn-new",attrs:{"icon-right":"add","label":"Nouveau document","disable":!_vm.getIsActiveCreateDocument},on:{"click":function($event){_vm.isCreateDocument = true}}})],1):_vm._e(),(_vm.isActionSeeAll)?_c('div',{staticClass:"action"},[_c('q-btn',{staticClass:"btn-new",attrs:{"disable":!_vm.getIsActiveCreateDocument,"icon-right":"add","label":"Nouveau document"},on:{"click":function($event){_vm.isCreateDocument = true}}}),_c('q-btn',{class:{ 'btn-see': true, disabled: !_vm.getLastDocuments.length },attrs:{"icon-right":"trending_flat","label":"Voir les documents"},on:{"click":function($event){return _vm.$router.push('/documents')}}})],1):_vm._e()]),_c('div',{staticClass:"bottom"},[(
        (_vm.isAllDocuments && _vm.getDocuments.length) ||
          (!_vm.isAllDocuments && _vm.getLastDocuments.length)
      )?_c('div',{staticClass:"row"},[_c('TableDocuments',{attrs:{"documents":_vm.isAllDocuments
            ? _vm.isSearch
              ? _vm.searchAll
              : _vm.getDocuments
            : _vm.isSearch
            ? _vm.searchLast
            : _vm.getLastDocuments},on:{"download":_vm.download}})],1):_c('LoadingOrEmpty',{attrs:{"custom-empty":true,"loading":_vm.isAllDocuments ? _vm.getLoadingDocuments : _vm.getLoadingLastDocuments},scopedSlots:_vm._u([{key:"customEmpty",fn:function(){return [_c('div',{staticClass:"no-documents"},[_vm._v("Aucun document créé")])]},proxy:true}])})],1),_c('CreateDocumentModal',{attrs:{"is-show":_vm.isCreateDocument,"extended":true},on:{"update:isShow":function($event){_vm.isCreateDocument=$event},"update:is-show":function($event){_vm.isCreateDocument=$event},"closeModal":_vm.closeModal}}),_c('a',{ref:"downloadLink",attrs:{"href":"","download":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }