<template>
  <div class="table-documents">
    <div class="top">
      <div class="title">{{ title }}</div>
      <div class="action" v-if="isAction">
        <q-btn
          class="btn-new"
          icon-right="add"
          label="Nouveau document"
          :disable="!getIsActiveCreateDocument"
          @click="isCreateDocument = true"
        />
      </div>
      <div class="action" v-if="isActionSeeAll">
        <q-btn
          class="btn-new"
          :disable="!getIsActiveCreateDocument"
          icon-right="add"
          label="Nouveau document"
          @click="isCreateDocument = true"
        />
        <q-btn
          :class="{ 'btn-see': true, disabled: !getLastDocuments.length }"
          @click="$router.push('/documents')"
          icon-right="trending_flat"
          label="Voir les documents"
        />
      </div>
    </div>
    <div class="bottom">
      <div
        class="row"
        v-if="
          (isAllDocuments && getDocuments.length) ||
            (!isAllDocuments && getLastDocuments.length)
        "
      >
        <TableDocuments
          :documents="
            isAllDocuments
              ? isSearch
                ? searchAll
                : getDocuments
              : isSearch
              ? searchLast
              : getLastDocuments
          "
          @download="download"
        />
      </div>
      <LoadingOrEmpty
        v-else
        :custom-empty="true"
        :loading="
          isAllDocuments ? getLoadingDocuments : getLoadingLastDocuments
        "
      >
        <template v-slot:customEmpty>
          <div class="no-documents">Aucun document créé</div>
        </template>
      </LoadingOrEmpty>
    </div>
    <CreateDocumentModal
      :is-show.sync="isCreateDocument"
      :extended="true"
      @closeModal="closeModal"
    />
    <a href="" download ref="downloadLink"></a>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty.vue'
import CreateDocumentModal from '@/components/modals/CreateDocument'
import TableDocuments from '@/components/documents/TableDocuments'
import { format } from 'date-fns'
import axios from 'axios';

export default {
  name: 'TableDocumentsBlock',
  components: {
    TableDocuments,
    CreateDocumentModal,
    LoadingOrEmpty
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    isAction: {
      type: Boolean,
      default: false
    },
    isActionSeeAll: {
      type: Boolean,
      default: false
    },
    isAllDocuments: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchAll: [],
      isSearch: false,
      searchLast: [],
      isCreateDocument: false
    }
  },
  async created() {
    this.setSearchDocuments({ message: '' })
    this.isAllDocuments
      ? await this.fetchDocuments()
      : await this.fetchLastDocuments()
  },
  computed: {
    ...mapGetters('documents', [
      'getLastDocuments',
      'getLoadingLastDocuments',
      'getErrorLastDocuments',
      'getDocuments',
      'getLoadingDocuments',
      'getErrorDocuments',
      'getIsActiveCreateDocument',
      'getSearch'
    ])
  },
  methods: {
    ...mapActions('documents', [
      'fetchLastDocuments',
      'fetchDocuments',
      'setSearchDocuments'
    ]),
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    closeModal() {
      this.isCreateDocument = false
    },
    download(url, reportName, reportType) {
      var name = reportName

      if (reportType == 'DOCX') {
        name = name + '.docx'
      } else if (reportType == 'EXCEL') {
        name = name + '.xlsx'
      } else {
        name = name + '.txt'
      }

      axios.get(url, {
        headers: {
          "Content-Type": "application/octet-stream"
        },
        responseType: "blob"
      })
        .then(response => {
          console.log('hello ?')
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(response.data);
          a.href = url;
          a.download = name;
          a.click();
        })
        .catch(err => {
          console.log("error", err);
        });
    },
    buildSearchLastMethod(message) {
      this.searchLast = this.getLastDocuments.filter(item => {
        const updatedOn = item.updatedOn.replace(/\s/, 'T') || new Date()
        const date = format(new Date(updatedOn), 'dd/MM/yyyy')
        if (
          item.reportName.toLowerCase().indexOf(message) > -1 ||
          item.projectName.toLowerCase().indexOf(message) > -1 ||
          item.reportStatus.toLowerCase().indexOf(message) > -1 ||
          date.indexOf(message) > -1
        ) {
          return item
        }
      })
    },
    buildSearchAllMethod(message) {
      this.searchAll = this.getDocuments.filter(item => {
        const updatedOn = item.updatedOn.replace(/\s/, 'T') || new Date()
        const date = format(new Date(updatedOn), 'dd/MM/yyyy')
        if (
          item.reportName.toLowerCase().indexOf(message) > -1 ||
          item.projectName.toLowerCase().indexOf(message) > -1 ||
          item.reportStatus.toLowerCase().indexOf(message) > -1 ||
          date.indexOf(message) > -1
        ) {
          return item
        }
      })
    }
  },
  watch: {
    getErrorLastDocuments: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorDocuments: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getSearch: function(message) {
      if (message && message.length) {
        this.isSearch = true

        if (this.getLastDocuments.length) {
          this.buildSearchLastMethod(message)
        }

        if (this.getDocuments.length) {
          this.buildSearchAllMethod(message)
        }
      } else {
        this.isSearch = false
      }
    }
  }
}
</script>
